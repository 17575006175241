<template>
    <div class="xi">
        <hd-com></hd-com>
        <searchhdCom></searchhdCom>
        <div class="shopTab">
            <div class="shopTabBox">
                <div class="shopTabno">
                    商城首页
                </div>
                <div class="shopTabChecked">
                    药品
                </div>
                <div class="shopTabChecked">
                    中药材
                </div>
                <div class="shopTabChecked">
                    食品
                </div>
            </div>
        </div>
        <div class="content-wrap">
            <div>
                <div class="menu-box">
                    <div class="content clearfix" style="position: relative;">
                        <div class="flex-start box">
                            <div class="menu-title">视频分类：</div>
                            <div class="menu-content flex-start">
                                <div class="menu-item" :class="{ menuActive: item.id == activeMenu.id }"
                                    v-for="(item, index) in CategoriesList" :key="index" @click="selectMenu(index)">{{
                                        item.name
                                    }}
                                </div>
                            </div>
                        </div>
                        <div class="flex-start box">
                            <div class="menu-title">视频主题：</div>
                            <div class="menu-content flex-start flex-wrap">
                                <div class="menu-item" :class="{ menuActive: item.id == activeMenuChi.id }"
                                    v-for="(item, index) in activeMenu.children" :key="index" @click="selectMenuChi(index)">
                                    {{ item.name }}
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="center-box">
                    <div class="content clearfix" style="position: relative;">
                        <div class="center-top flex-space-between">
                            <div class="top-left flex-space-between">
                                <div class="title" :class="{ active: orderLeft == 0 }" @click="orderData(0)">综合</div>
                                <div class="line-div"></div>
                                <div class="title" :class="{ active: orderLeft == 1 }" @click="orderData(1)">最新课程</div>
                            </div>
                            <div class="top-center">{{ activeMenuChi.name || "全部" }}</div>
                            <div class="top-right">
                                <el-checkbox-group v-model="checkList" @change="checkData()">
                                    <el-checkbox label="0">免费视频</el-checkbox>
                                    <el-checkbox label="1">收费视频</el-checkbox>
                                </el-checkbox-group>
                                <!-- <el-radio-group v-model="checkList" @input="checkData()">
                                    <el-radio label="0">免费视频</el-radio>
                                    <el-radio label="1">收费视频</el-radio>
                                </el-radio-group> -->
                            </div>
                        </div>
                        <template v-if="videoList.length > 0">
                            <div class="center-center flex-start flex-wrap">
                                <div class="video-item flex-space-between-columns" v-for="(item, index) in videoList"
                                    :key="index" @click="goDetail(item)">
                                    <div class="item-img">
                                        <img src="../../assets/img/contact-us-img.jpg"
                                            v-if="!item.cover && !item.aliyunCoverUrl" style="width:100%; height:100%" />
                                        <img v-else :src="item.cover ? item.cover : item.aliyunCoverUrl" alt="" srcset=""
                                            style="width:100%; height:100%">
                                        <div class="mark" v-if="item.isCharge">付费</div>
                                    </div>

                                    <div class="desc-box  flex-space-between-columns">
                                        <div class="desc-top">
                                            <div class="title1 oneLines">
                                                <!-- <el-tooltip class="item" effect="dark" :content="item.name"
                                                    placement="top-start"> -->
                                                    <div>{{ item.name }}</div>
                                                <!-- </el-tooltip> -->
                                            </div>
                                            <div class="title2 twoLines">
                                                <!-- <el-tooltip class="item" effect="dark" :content="item.introduce"
                                                    placement="top-start"> -->
                                                    <div>{{ item.introduce }}</div>
                                                <!-- </el-tooltip> -->
                                            </div>
                                        </div>
                                        <div class="desc-bottom flex-space-between">
                                            <template v-if="item.isCharge == false">
                                                <div class="bottom-left">{{ item.visitCount }}人已观看</div>
                                                <div class="bottom-right1">免费</div>
                                            </template>
                                            <template v-else>
                                                <div class="bottom-left">{{ item.totalSale }}人已购买</div>
                                                <div class="bottom-right2">
                                                    <span style="color:#E41313;background: none;">￥{{ item.userPrice
                                                    }}</span>
                                                    <!-- 会员价：<span>￥{{ item.viP2Price }}</span> -->
                                                    <!-- <p class="price">￥{{ item.userPrice }}</p> -->
                                                </div>
                                            </template>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="page">
                                <el-pagination background layout="prev, pager, next,total" :page-size="15"
                                    @current-change="handleCurrentChange" :current-page.sync="currentPage"
                                    :total="DetailTotal">
                                </el-pagination>
                            </div>
                        </template>
                        <div v-else class="flex-center" style="margin:50px auto">
                            <img src="../../assets/img/video/blank_page.png" />
                        </div>

                    </div>

                </div>
            </div>
        </div>
        <ft-com></ft-com>
        <rt-bar></rt-bar>
    </div>
</template>

<script>


import hdCom from '../../components/intshopheader'
import searchhdCom from '../../components/insearchHeader'

import ftCom from '../../components/footer'
// import rtBar from "../../components/right";
import { videoMain } from "../../components/video/video"
export default {
    name: "healthKnow",
    components: {
        hdCom,
        searchhdCom,
        ftCom,
        // rtBar
    },
    data() {
        var video = new videoMain(this.TokenClient, this.Services.Statics)
        return {
            videoDomain: video,
            categoryId: 0,
            keyWord: ' ',
            pageIndex: 1,
            videoList: [],
            CategoriesList: [],
            curindex: -1,
            scrollW: 0,
            leftnum: 0,
            rightnum: 0,
            scrollW: 0,
            activeMenu: {
                id: 0,
                name: "全部",
                children: [{
                    id: 0,
                    name: "全部"
                }]
            },
            activeMenuChi: {
                id: 0,
                name: "全部"
            },
            orderLeft: 0,
            checkList: [],
            checkValue: 2,
            PageIndex: 1,
            PageTotal: 1,
            DetailTotal: 0,

            currentPage: 1, //初始页
        }
    },
    methods: {
        //分页点击事件
        handleCurrentChange: function (pageNum) {
            this.currentPage = pageNum;
            this.PageIndex = pageNum;
            this.Detail()
            // chrome
            document.body.scrollTop = 0;
            // firefox
            document.documentElement.scrollTop = 0;
            // safari
            window.pageYOffset = 0;
        },
        ChangeDetailPage(pageIndex) {
            this.PageIndex = pageIndex;
            this.Detail()
        },
        checkData() {
            this.videoList = []
            if (this.checkList.length == 0) {
                this.checkValue = 2
            }
            if (this.checkList.length == 1) {
                this.checkValue = this.checkList[0]
            }
            if (this.checkList.length == 2) {
                this.checkList.splice(0, 1)
                this.checkValue = this.checkList[0]
            }
            this.Detail()
        },
        orderData(num) {
            this.videoList = []
            this.orderLeft = num
            this.Detail()
            // 查询数据
        },
        selectMenuChi(i) {
            this.videoList = []
            this.activeMenuChi = this.activeMenu.children[i]
            this.categoryId = this.activeMenuChi.id
            this.Detail()
        },
        selectMenu(i) {
            this.videoList = []
            this.activeMenu = this.CategoriesList[i]
            this.activeMenuChi = this.activeMenu.children[0]
            this.categoryId = this.activeMenu.id
            this.Detail()
        },
        goDetail(e) {
            this.$router.push({
                path: './videoDetail',
                query: {
                    videoId: e.id
                }
            })
        },
        //详情获取
        Detail() {
            var _this = this;
            let params = `${_this.categoryId}/${_this.keyWord}/${_this.PageIndex}/${_this.checkValue}/${_this.orderLeft}`
            _this.videoDomain.GetAllVideoList(params, function (data) {
                if (data.data.results)
                    _this.videoList = (data.data.results)
                if (_this.videoList.length > 0)
                    for (let i = 0; i < _this.videoList.length; i++) {
                        _this.videoList[i].addTime = _this.videoList[i].addTime.slice(0, 10)
                    }
                _this.PageIndex = data.data.pageIndex;
                _this.PageTotal = data.data.pageCount;
                _this.DetailTotal = data.data.dataTotal;
            },
                function (error) {

                })
        },
        getCategoriesList() {
            var _this = this
            _this.videoDomain.getCategories(function (data) {
                _this.CategoriesList = (data.data)
                _this.CategoriesList.unshift({
                    id: 0,
                    name: "全部",
                    children: []
                })
                _this.CategoriesList.forEach((item) => {
                    if (item.children) {
                        item.children.unshift({
                            id: 0,
                            name: "全部",
                        })
                    } else {
                        item.children = [{
                            id: 0,
                            name: "全部",
                        }]
                    }
                })
            },
                function (error) {

                })
        },
    },
    mounted() {
        this.Detail();
        this.getCategoriesList()
    },
    destroyed() {
        //销毁监听
    },
}
</script>
<style lang="scss" scoped>
.shopTab {
    border-bottom: 1px solid #00AFB5;
}
.shopTabBox {
    width: 70%;
    display: flex;
    align-items: center;
    margin-left: 15%;
}
.shopTabno {
    font-size: 15px;
    color: #FFFFFF;
    font-weight: bold;
    padding: 10px 50px;
    background: #00AFB5;
    cursor: pointer;
}
.shopTabChecked {
    font-size: 15px;
    padding: 10px 50px;
     cursor: pointer;
}
.banner {
    height: 116px;
    background: url("../../assets/img/jbk_banner.png")center no-repeat;
}

.banner-text {
    width: 1200px;
    margin: 0 auto;

    h3 {
        padding-top: 25px;
        font-size: 26px;
        font-weight: normal;
        color: #ffffff;
    }

    p {
        font-size: 18px;
        color: #ffffff;

        a {
            color: #ffffff;
            font-size: 14px;
            margin: 0 5px;
        }
    }
}

.content-wrap {
    background: #F5F6F6;
}


.content {
    position: relative;
    width: 1200px;
    margin: 0 auto;
    padding: 30px 0;

}

.menu-box {
    width: 100%;
    background: #fff;
    height: auto;

    .box {
        align-items: flex-start !important;
        margin-bottom: 10px;
    }

    .menu-title {
        width: 90px;
        font-size: 16px;
        height: 40px;
        line-height: 40px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #999999;
    }

    .menu-content {
        width: calc(100% - 95px);
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #999999;
    }

    .menu-item {
        width: auto;
        margin: 0 5px;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 40px;
        height: 40px;
        padding: 0 10px;
    }

    .menu-item:hover {
        background: #F5F6F6;
        border-radius: 2px;
        color: #00998B;
        cursor: pointer;
    }

    .menuActive {
        background: #F5F6F6;
        border-radius: 2px;
        color: #00998B;
    }
}

.center-box {
    .center-top {
        height: 120px;

        .top-left {
            .title {
                font-size: 16px;
                font-family: PingFang SC;
                font-weight: 400;
                color: #D9D9D9;
                cursor: pointer;
            }

            .title:hover {
                color: #00998B;
            }

            div.active {
                color: #00998B;
            }

            .line-div {
                width: 1px;
                height: 16px;
                background: #D9D9D9;
                margin: 0 30px;
            }
        }
    }

    .top-center {
        width: auto;
        height: 70px;
        line-height: 70px;
        font-size: 24px;
        font-family: PingFang SC;
        font-weight: 800;
        color: #333333;
        background: url("../../assets/img/video/m_title.png")center no-repeat;
    }

    .center-center {
        .video-item {
            width: 23.5%;
            height: 330px;
            margin-right: 2%;
            margin-bottom: 30px;
            background: #ffffff;
            border-radius: 0px 0px 4px 4px;
            cursor: pointer;

            .item-img {
                width: 100%;
                height: 164px;
                background: #000;
                border-radius: 4px 4px 0px 0px;
                position: relative;

                img {
                    border-radius: 4px 4px 0px 0px;
                }

                .mark {
                    position: absolute;
                    top: 10px;
                    left: 10px;
                    width: 54px;
                    height: 26px;
                    background: #EA3553;
                    border-radius: 4px;
                    color: #fff;
                    text-align: center;
                }
            }

            .desc-box {
                width: 90%;
                padding: 5%;
                height: 166px;

                .desc-top {
                    width: 100%;
                    font-size: 14px;
                    font-family: PingFang SC;
                    font-weight: 400;

                    .title1 {
                        font-size: 18px;
                        width: 100%;
                    }

                    .title2 {
                        width: 100%;
                        color: #999999;
                        -webkit-line-clamp: 3 !important;
                    }
                }
            }

            .desc-bottom {
                width: 100%;

                .bottom-right1 {
                    color: #00998B;
                }

                .bottom-right2 {
                    color: #875427;
                    position: relative;

                    p.price {
                        color: #E41313;
                        position: absolute;
                        right: 0;
                        top: -25px;
                        padding: 2px 10px;
                    }

                    span {
                        color: #875427;
                        background: #F4DBAB;
                        padding: 2px 10px;
                        font-size: 14px;
                        border-radius: 2px;
                    }
                }

            }

            .top-right {
                width: auto;
                height: 70px;
                line-height: 70px;
                font-size: 24px;
            }

        }

        .video-item:hover {
            box-shadow: 0px 8px 15px 0px rgba(186, 186, 186, 0.3);
        }

        .video-item:nth-child(4n) {
            margin-right: 0;
        }

    }

}


.healthKnow .el-radio {
    margin-right: 30px;
}

#page ::v-deep .el-pagination {
    text-align: center;
    margin: 30px auto;
}

#page ::v-deep .el-pagination.is-background .btn-next,
#page ::v-deep .el-pagination.is-background .btn-prev,
#page ::v-deep .el-pagination.is-background .el-pager li {
    background-color: #fff;
}

#page ::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #01C2AC;
}

#page ::v-deep .el-pagination.is-background .btn-next,
#page ::v-deep .el-pagination.is-background .btn-prev,
#page ::v-deep .el-pagination.is-background .el-pager li {
    border-radius: 2px;
}
</style>
